const React = require('react')
const scrollReveal = require('@swp/scroll-reveal')

module.exports = function Wrapper({ children, path }) {
  React.useEffect(() => {
    return scrollReveal()
  }, [path])

  return children
}
