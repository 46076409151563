const React = require('react')
const Wrapper = require('./Wrapper')

export function wrapPageElement({ element, props }, { plugins, ...options }) {
  return (
    <Wrapper options={options} path={props.path}>
      {element}
    </Wrapper>
  )
}
